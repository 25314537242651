.industry-list-section {
  margin: 32px 0 48px;
  display: flex;
  flex-direction: column;
}

.industry-list__content {
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
	padding: 16px;
	width: 33%;
	flex-grow: 1;
}