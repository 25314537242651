body {
	font-family: Arial, Helvetica, sans-serif;
	background-color: var(--wbn-primary-color_background);
}

.ui.search > .ui.input.icon {
	width: 100%;
}

p {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
}

ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
